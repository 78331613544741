export default {
  addProduct: "Mahsulot qo'shish",
  updateProduct: "Mahsulotni yangilash",
  image: 'Rasm',
  success: "Mahsulot muvaffaqiyatli qo'shildi!",
  delete: "Ushbu mahsulotni o'chirmoqchimisiz?",
  delete2: "Ushbu mahsulotlarni o'chirmoqchimisiz?",
  filter: 'Tartiblash',
  filterLast: 'Oxiridan',
  myProductsInWarehouse: 'Mening Mahsulotlarim',
  productsInWarehouse: 'Flakon.uz omboridagi mahsulotlar',
}