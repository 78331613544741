export default {
  addProduct: 'Добавить продукт',
  updateProduct: 'Обновить продукт',
  image: 'Изображение',
  success: 'Товар успешно добавлен!',
  delete: 'Вы хотите удалить этот продукт?',
  delete2: 'Вы хотите удалить эти продукты?',
  filter: 'Фильтр',
  filterLast: 'От последнего',
  myProductsInWarehouse: 'Мои Товары',
  productsInWarehouse: 'Товары на складе Flakon.uz',
}