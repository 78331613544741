export default {
  addProduct: 'Add Product',
  updateProduct: 'Update Product',
  image: 'Image',
  success: 'Product successfully added!',
  delete: 'Do you want to delete this product?',
  delete2: 'Do you want to delete these products?',
  filter: 'Filter',
  filterLast: 'From Last',
  myProductsInWarehouse: 'My Products',
  productsInWarehouse: 'Products in Flakon.uz warehouse',
}